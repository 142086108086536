@layer base {
  .main {
  display: flex;
  margin-top: var(--sk-space-16);
  color: var(--sk-color-grey-600);
  font-weight: var(--sk-typography-body-1-regular-font-weight);
}

.icon {
  margin-top: var(--sk-space-4);
  line-height: var(--sk-line-heights-3);
}

.text {
  margin-left: var(--sk-space-4);
  font-size: var(--sk-font-size-1);
  line-height: var(--sk-line-heights-3);
  vertical-align: middle;
}

}