@layer base {
  .main {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
  text-align: center;
}

.logos {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--sk-space-16);
}

.logos img {
  border-radius: var(--sk-radius-4);
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
}

.actions {
  display: flex;
  flex-direction: column-reverse;
  gap: var(--sk-space-16);
  justify-content: center;
  margin-top: var(--sk-space-8);
}

@media (--sk-medium-viewport) {
  .actions {
    flex-direction: row;
  }
}

.btn__loading {
  background-color: var(--sk-action-button-color-background-highlight-disabled);
  color: var(--sk-action-button-color-highlight-disabled);
  pointer-events: none;
}

}