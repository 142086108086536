@layer base {
  .main {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

.actions {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  gap: var(--sk-space-8);
}

@media (--sk-medium-viewport) {
  .actions {
    flex-direction: row;
  }
}

}