@layer base {
  .status {
  position: absolute;
  bottom: 0;
  color: var(--sk-color-grey-100);
  font-weight: bold;
  line-break: anywhere;
}

/* necessary for the skeleton to be exactly the desired height */
/* https://github.com/dvtng/react-loading-skeleton/issues/23#issuecomment-939231878 */
.registrationDetailsPlaceholder {
  display: block;
  line-height: 1;
}

}